<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Name') }}: {{ banner.name }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('State') }}: {{ banner.state }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Program') }}: {{ banner.program }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Property') }}: {{ banner.userProperty }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Segmentation') }}: {{ banner.segmentation }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Position') }}: {{ banner.position }}
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Video Link') }}:
                        <a :href="banner.videoLink" target="_blank" class="colorLink">
                            {{ banner.videoLink }}
                        </a>
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Image Link') }}:
                        <a :href="banner.imageLink" target="_blank" class="colorLink">
                            {{ banner.imageLink }}
                        </a>
                    </p>
                </article>
            </div>
        </div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Description') }}: {{ banner.description }}
                    </p>
                </article>
            </div>
        </div>
        <br>
        <div class="container columns is-centered is-half has-text-centered">
            <img :src="pages[0]"/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Show',

    inject: ['i18n', 'http', 'route', 'routerErrorHandler', 'toastr'],

    data: () => ({
        banner: {},
        bannerImages: {},
        pages: [],
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http.get(this.route('banner.show', this.$route.params.banner))
                .then(({ data }) => {
                    this.banner = data.banner;
                    this.bannerImages = data.banner.banner_images;
                    this.bannerImages.forEach(bannerImage => {
                        this.pages.push(this.route('bannerImages.downloadImage', bannerImage.id));
                    });
                }).catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.title-menu {
    font-size: 1.5rem;
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.2px;
    color: $secondary;
}

.section-style{
    background: radial-gradient(circle, rgba(72,95,199,1) 35%, rgba(9,9,121,1) 100%);
    padding: 10px;
}

.titles{
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.titles1{
    text-align: center;
    font-family: Bahnschrift,serif;
    text-transform: capitalize;
    font-weight: bold;
    color: $secondary;
}

.space{
    padding: 3px !important;
    &.btn {
        cursor: pointer;
    }
}

.butt{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.textEmail{
    text-transform: none !important;
}

.colorLink{
    color: $blueToneOne;
}

</style>
